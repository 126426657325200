import { graphql, useStaticQuery } from 'gatsby'
import { Helmet } from 'react-helmet'
import { Grid, Heading, Text } from 'theme-ui'

import { BaseLayout } from '../layout/base'
import { Footer } from '../components/Footer'
import { Section } from '../components/Section'
import { Container } from '../components/Container'
import { Link } from '../components/Link'
import { base } from '../theme'

const PartnersPage = () => {
  const data = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          contact {
            phone
          }
        }
      }
    }
  `)
  const phone = data.site.siteMetadata.contact.phone

  return (
    <BaseLayout>
      <Helmet>
        <title>Informace pro zprostředkovatele | Privy</title>
      </Helmet>

      <main>
        <Section
          sx={{
            backgroundColor: 'white',
          }}
        >
          <Container>
            <Grid
              sx={{
                py: [4, null, null, 16],
                gap: 8,
              }}
            >
              <Heading
                as="h1"
                sx={{
                  fontSize: ['2xl', '4xl', '5xl', '6xl'],
                }}
                data-sal="slide-left"
                data-sal-duration="300"
                data-sal-delay="100"
                data-sal-easing="ease"
              >
                Pro zprostředkovatele
              </Heading>

              <Text
                as="p"
                sx={{ fontSize: ['lg', null, null, 'xl'] }}
                data-sal="fade"
                data-sal-duration="300"
                data-sal-delay="100"
                data-sal-easing="ease"
              >
                Nabízíme několik možností spolupráce. Pro více informací
                zavolejte nebo nám napište na e-mail{' '}
                <Link to="mailto:spoluprace@privy.cz">
                  spoluprace@privy.cz
                </Link>
                . Obratem se Vám ozveme.
              </Text>

              <Link
                customStyle={{
                  // color: base.colors.brandPrimary['600'],
                  color: 'brandPrimary.600',
                  fontSize: ['2xl', '2xl', '3xl', '4xl'],
                  fontWeight: 'bold',
                  textAlign: ['center', null, null, 'start'],
                  textDecoration: 'none',
                  '&:hover': {
                    textDecoration: 'underline',
                  },
                }}
                to={`tel:${phone.replace(/ /gi, '')}`}
                data-sal="slide-right"
                data-sal-duration="300"
                data-sal-delay="400"
                data-sal-easing="ease"
              >
                <Text sx={{ color: 'brandPrimary.500' }}>{phone}</Text>
              </Link>
            </Grid>
          </Container>
        </Section>
      </main>

      <Footer />
    </BaseLayout>
  )
}

export default PartnersPage
